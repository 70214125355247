<template>
  <div>
    <!--begin:: App Smart List-->
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :title="$t('label.talentPackage')"
      :accessRights="accessRights"
      :actions="actions"
      :tableHeaders="headers"
      @app-smart-list-open-modal-details="
        (id) => openModalDetails(id, 'talent')
      "
    ></AppSmartList>
    <!--end:: AppSmartList-->
    <!--begin:: App Smart List-->
    <AppSmartList
      class="mt-3"
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :title="$t('label.productionPackage')"
      :accessRights="accessRights"
      :actions="productionActions"
      :tableHeaders="productionHeaders"
      @app-smart-list-open-modal-details="
        (id) => openModalDetails(id, 'production')
      "
    ></AppSmartList>
    <!--end:: AppSmartList-->

    <!--begin:: App Update Details Modal-->
    <UserPackageDetailsModal
      v-model="appUpdateDetailsModalObj.isShow"
      :id="appUpdateDetailsModalObj.id"
      :modalType="appUpdateDetailsModalObj.modalType"
    ></UserPackageDetailsModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import UserPackageDetailsModal from '@/views/components/user-package/UserPackageDetailsModal.vue';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION, APP_COMPONENT_NAME } from '@/core/constants';
  import {
    PACKAGE_GET_TALENT_PACKAGE_LIST,
    PACKAGE_INITIAL_TALENT_PACKAGE_LIST_STATE,
    PACKAGE_GET_PRODUCTION_PACKAGE_LIST,
    PACKAGE_INITIAL_PRODUCTION_PACKAGE_LIST_STATE
  } from '@/core/store/package.module';

  export default {
    name: 'PackageList',
    components: {
      AppSmartList,
      UserPackageDetailsModal
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [{ title: i18nHelper.getMessage('label.userPackage') }]
      },
      accessRights: {
        moduleName: MODULE.PACKAGE,
        actions: {
          create: ACTION.APP_INFO_CREATE,
          read: ACTION.APP_INFO_READ,
          update: ACTION.APP_INFO_UPDATE,
          delete: ACTION.APP_INFO_DELETE
        }
      },
      actions: {
        viewList: {
          module: 'userPackage',
          state: 'talentPackageList',
          action: PACKAGE_GET_PRODUCTION_PACKAGE_LIST,
          initialStateAction: PACKAGE_INITIAL_PRODUCTION_PACKAGE_LIST_STATE,
          title: i18nHelper.getMessage('label.getProductionPackageList')
        }
      },
      productionActions: {
        viewList: {
          module: 'userPackage',
          state: 'productionPackageList',
          action: PACKAGE_GET_TALENT_PACKAGE_LIST,
          initialStateAction: PACKAGE_INITIAL_TALENT_PACKAGE_LIST_STATE,
          title: i18nHelper.getMessage('label.getUserPackageList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'price_per_month',
          name: 'price_per_month',
          active: true,
          sortable: true
        },
        {
          key: 'level',
          name: 'level',
          active: true,
          sortable: true
        },
        {
          key: 'addon_video_count',
          name: 'addon_video_count',
          active: true,
          sortable: true
        },
        {
          key: 'addon_video_price',
          name: 'addon_video_price',
          active: true,
          sortable: true
        },
        {
          key: 'casting_per_month',
          name: 'casting_per_month',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true
          }
        }
        // {
        //   key: 'forceUpdate',
        //   name: 'forceUpdate',
        //   active: true,
        //   sortable: true,
        //   layout: APP_COMPONENT_NAME.APP_ENUM_LABEL,
        //   config: {
        //     size: 'lg',
        //     enumKey: 'enumYesNo',
        //     statusColor: (value) => {
        //       let color = {
        //         0: 'danger',
        //         1: 'success'
        //       };

        //       return color[value];
        //     }
        //   }
        // },
      ],
      productionHeaders: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'level',
          name: 'level',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true
          }
        }
      ],
      appUpdateDetailsModalObj: {
        isShow: false,
        id: 0,
        modalType: ''
      }
    }),
    methods: {
      openModalDetails(value, type) {
        this.openAppUpdateDetailsModal(value, type);
      },
      openAppUpdateDetailsModal(id, type) {
        this.appUpdateDetailsModalObj = {
          isShow: true,
          id: id,
          modalType: type
        };
      }
    }
  };
</script>

<style></style>
