<template>
  <b-modal
    v-model="modal"
    :title="$t('label.packageUpdateDetails')"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-325px"
  >
    <!-- begin:: Modal Body -->
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2 mb-6">
          <!-- begin:: Role Label -->
          <div v-if="i.model == 'role'" class="mb-6 font-size-lg">
            <span>{{ $t(`label.${i.title}`) }}</span>
            <multiselect
              v-model="$v.appUpdateObj[i.model].$model"
              :options="i.options"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              placeholder="Pick a value"
            ></multiselect>
          </div>
          <!-- end:: Role Label -->

          <!-- begin:: Form input -->
          <AppFormField
            v-else
            v-model="$v.appUpdateObj[i.model].$model"
            :state="validateObjState('appUpdateObj', i.model)"
            :validationValue="$v.appUpdateObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :formType="i.formType"
            :options="i.options"
            :disabled="isFormDisabled"
          ></AppFormField>
          <!-- end:: Form Input -->
        </div>
      </template>
    </b-form-group>
    <!-- end:: Modal Body -->

    <!-- begin:: Modal Footer -->
    <template #modal-footer>
      <template v-if="isShowActions">
        <b-button class="mr-2" variant="white" @click="actionDiscard">{{
          $t('label.discard')
        }}</b-button>

        <b-button variant="primary" @click="actionSaveChanges">{{
          $t('label.saveChanges')
        }}</b-button>
      </template>
      <b-button v-else variant="primary" @click="actionDiscard">{{
        $t('label.close')
      }}</b-button>
    </template>
    <!-- end:: Modal Footer -->
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  // import { listService } from '@/core/services';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import {
    required,
    //  url,
    decimal,
    numeric
  } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    PACKAGE_GET_USER_PACKAGE,
    PACKAGE_INITIAL_USER_PACKAGE_STATE,
    PACKAGE_INITIAL_UPDATE_USER_PACKAGE_STATE,
    PACKAGE_UPDATE_USER_PACKAGE,
    PACKAGE_RESET_USER_PACKAGE_LIST
  } from '@/core/store/package.module';
  import { CONSTANTS_CONSTANTS } from '@/core/store/constants.module';
  import _ from 'lodash';
  export default {
    name: 'UserPackageDetailsModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      modalType: {
        type: String,
        default: 'talent',
        required: true
      }
    },
    data: () => ({
      modal: false,
      appUpdateObj:
        this?.modalType === 'talent'
          ? {
              name: null,
              level: 1,
              package_details: [],
              casting_per_month: 1,
              price_per_month: 1,
              addon_video_price: 1,
              addon_video_count: 1,
              is_active: true
            }
          : {
              name: null,
              level: 1,
              price_per_annum: 0,
              no_of_users: 1
            },
      formFields: []
    }),
    computed: {
      appInfoComplete() {
        return this.$store.state.userPackage.appInfo.complete;
      },
      updateAppInfoComplete() {
        return this.$store.state.userPackage.updateAppInfo.complete;
      },
      isFormDisabled() {
        return !this.checkAccessRight(MODULE.APP_INFO, [
          ACTION.APP_INFO_UPDATE
        ]);
      },
      isShowActions() {
        return this.checkAccessRight(MODULE.APP_INFO, [ACTION.APP_INFO_UPDATE]);
      }
    },
    watch: {
      value() {
        if (this.value) {
          this.getAppInfo(this.id);
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      appInfoComplete() {
        let response = this.$store.state.userPackage.appInfo;
        let title = i18nHelper.getMessage('label.getPackage');
        let initialStateAction = PACKAGE_INITIAL_USER_PACKAGE_STATE;

        let successAction = (response) => {
          console.log(response);
          this.getAppInfoCompleteActions(response);
        };

        if (response.complete) {
          this.initFormFields();
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateAppInfoComplete() {
        let response = this.$store.state.userPackage.updateAppInfo;
        let title = i18nHelper.getMessage('label.updatePackage');
        let initialStateAction = PACKAGE_INITIAL_UPDATE_USER_PACKAGE_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.$router.go();
        };
        let errorAction = () => {
          this.actionCloseModal();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getAppInfoCompleteActions(response) {
        this.appUpdateObj = response.data;
        console.log(this.appUpdateObj);
        this.modal = true;
        this.$v.$reset();
      },
      actionDiscard() {
        this.$v.$reset();
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('appUpdateObj')) {
          this.updateAppInfo(this.id, this.appUpdateObj);
        }
      },
      resetForm() {
        this.appUpdateObj =
          this?.modalType === 'talent'
            ? {
                name: null,
                level: 1,
                role: [],
                package_details: [],
                casting_per_month: 1,
                price_per_month: 1,
                addon_video_price: 1,
                addon_video_count: 1,
                is_active: true
              }
            : {
                name: null,
                level: 1,
                role: [],
                buy_admin: [],
                price_per_admin: 0,
                no_of_free_users: 0,
                price_per_annum: 0,
                no_of_users: 1
              };

        this.$v.$reset();
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      getAppInfo(id) {
        this.$store.dispatch(PACKAGE_RESET_USER_PACKAGE_LIST);
        this.$store.dispatch(PACKAGE_GET_USER_PACKAGE, {
          id,
          modalType: this.modalType
        });
      },
      updateAppInfo(id, data) {
        this.$store.dispatch(PACKAGE_UPDATE_USER_PACKAGE, {
          id,
          data,
          modalType: this.modalType
        });
      },
      initFormFields() {
        console.log(
          _.get(this, `$store.getters.${CONSTANTS_CONSTANTS}.userRole`)
        );
        this.formFields =
          this?.modalType === 'talent'
            ? [
                {
                  model: 'name',
                  title: 'name',
                  validators: ['required']
                },
                {
                  model: 'level',
                  title: 'level',
                  validators: ['required', 'numeric']
                },
                {
                  model: 'price_per_month',
                  title: 'price_per_month',
                  validators: ['required', 'decimal']
                },
                {
                  model: 'casting_per_month',
                  title: 'casting_per_month',
                  validators: ['required', 'numeric']
                },
                {
                  model: 'addon_video_price',
                  title: 'addon_video_price',
                  validators: ['required']
                },
                {
                  model: 'addon_video_count',
                  title: 'addon_video_count',
                  validators: ['required', 'numeric']
                },
                ...(
                  this?.$store?.state?.userPackage?.appInfo?.data
                    ?.package_details_key || []
                )
                  .filter((i) => !/id/i.test(i))
                  .map((i) => ({
                    model: i,
                    title: i,
                    type: 'number',
                    validators: /price/i.test(i)
                      ? ['required', 'decimal']
                      : ['required', 'numeric']
                  }))
              ]
            : [
                {
                  model: 'name',
                  title: 'name',
                  validators: ['required']
                },
                {
                  model: 'level',
                  title: 'level',
                  validators: ['required', 'numeric']
                },
                {
                  model: 'role',
                  title: 'role',
                  validators: ['required'],
                  formType: 'select',
                  options: _.get(
                    this,
                    `$store.getters.${CONSTANTS_CONSTANTS}.userRole`,
                    []
                  ).map((i) => i.value)
                },
                {
                  model: 'buy_admin',
                  title: 'buy_admin',
                  validators: ['required'],
                  formType: 'select',
                  options: [
                    {
                      text: 'Yes',
                      value: true
                    },
                    {
                      text: 'no',
                      value: false
                    }
                  ]
                },

                {
                  model: 'price_per_annum',
                  title: 'price_per_annum',
                  validators: ['required', 'decimal']
                },
                {
                  model: 'no_of_users',
                  title: 'no_of_users',
                  validators: ['required', 'numeric']
                },
                {
                  model: 'price_per_admin',
                  title: 'price_per_admin',
                  validators: ['required', 'decimal']
                },
                {
                  model: 'no_of_free_users',
                  title: 'no_of_free_users',
                  validators: ['required', 'numeric']
                }
              ];
      }
    },
    validations() {
      return this?.modalType === 'talent'
        ? {
            appUpdateObj: {
              name: {
                required
              },
              level: {
                required,
                numeric
              },
              price_per_month: {
                required,
                decimal
              },
              casting_per_month: {
                required,
                numeric
              },
              addon_video_price: {
                required,
                decimal
              },
              addon_video_count: {
                required,
                numeric
              },
              ...Object.assign(
                {},
                ...(this?.$store?.state?.userPackage?.appInfo?.data?.package_details_key.map(
                  (i) => ({
                    [i]: /price/i.test(i)
                      ? { required, decimal }
                      : {
                          required,
                          numeric
                        }
                  })
                ) || [])
              )
            }
          }
        : {
            appUpdateObj: {
              name: {
                required
              },
              buy_admin: {
                required
              },
              role: {
                required
              },
              level: {
                required,
                numeric
              },
              price_per_annum: {
                required,
                decimal
              },
              no_of_users: {
                required,
                numeric
              },
              price_per_admin: {
                required,
                decimal
              },
              no_of_free_users: {
                required,
                numeric
              }
            }
          };
    }
  };
</script>

<style lang="scss"></style>
